import * as React from 'react'
import {
  createStyles,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import SEO from '../components/SEO'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PortableText from 'react-portable-text'
import Book from '../model/book'
import BookReseller from '../model/book-reseller'
import { Variant } from '@material-ui/core/styles/createTypography'
import moment from 'moment'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    author: {},
    authorSection: {
      marginBottom: 20
    },
    bookTitle: {
      fontFamily: 'bree, sans-serif',
      fontStyle: 'normal',
      fontWeight: 800,
      textAlign: 'center',
    },
    bookSubtitle: {
      fontFamily: 'bree, sans-serif',
      fontStyle: 'normal',
      fontWeight: 700,
      textAlign: 'center',
      paddingBottom: 20,
    },
    center: {
      textAlign: 'center',
    },
    shortDescription: {
      textAlign: 'center',
      width: '80%',
      fontWeight: 'bold',
      paddingTop: 50,
      paddingBottom: 30,
    },
    longDescriptionBlock: {
      textAlign: 'left',
    },
    longDescription: {
      paddingBottom: 20,
    },
    metadata: {
      fontWeight: 'bold',
      width: '60%'
    },
    bookDetailsContainer: {
      width: '80%',
      margin: 'auto',
      paddingTop: 20,
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    backCoverImage: {
      width: '40%',
    },
    authorImage: {
      width: '60%',
    },
    resellers: {
      listStyle: 'none',
      paddingLeft: 0,
      textAlign: 'center'
    },
  }),
)

const BookPage = ({ data }: any) => {
  const classes = useStyles()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const book = data.book as Book

  const Retailers = (_) => {
    const links = book.resellers.map((reseller: BookReseller) => {
      return (
        <li key={reseller.label}>
          <a href={reseller.bookUrl} target='_blank'>
            <Typography variant='h4'>{reseller.label}</Typography>
          </a>
        </li>
      )
    })

    return <ul className={classes.resellers}>{links}</ul>
  }

  const availability = (rawDate: string): string => {
    const pubDate = moment(rawDate)
    return pubDate.isAfter(moment())
      ? 'Coming Soon!'
      : pubDate.format('MMMM D, YYYY')
  }

  const descVariant: Variant = mdUp ? 'h5' : 'body1'

  const Authors = () => {
    const authorList = book.authors.map((author) => (
      <Link to={`/authors/${author.slug.current}`}>
        <Typography variant='body2' className={classes.author}>
          {author.name}
        </Typography>
      </Link>
    ))
    return <div className={classes.authorSection}>
      <Typography variant='body2' className={classes.author}>
        Written by:
      </Typography>
      {authorList}
    </div>
  }

  const Metadata = (_) => {

    interface TextProps { children: any }
    const Text = ({ children }: TextProps) => (
      <Typography variant='body2' align='center' color='primary'>
        {children}
      </Typography>
    )

    return (
    <div className={classes.metadata}>
      <Text>{`ISBN: ${book.isbn}`}</Text>
      <Text>
        {`Availability: ${availability(book.publicationDate)}`}
      </Text>
      <Text>
        {`Suggested Retail Price: ${book.price}`}
      </Text>
      <Text>
        Available at these fine booksellers:
      </Text>
      <Retailers className={classes.resellers} />
    </div>
  )}

  const LongDescription = (_) => {
    const serializers = {
      normal: (props) => (
        <Typography variant={descVariant} className={classes.longDescription}>
          {props.children}
        </Typography>
      ),
    }

    return (
      <PortableText
        content={book._rawLongDescription}
        serializers={serializers}
        className={classes.longDescriptionBlock}
      />
    )
  }

  return (
    <>
      <SEO title={book.title} />
      <Grid
        container
        direction='column'
        className={classes.bookDetailsContainer}
      >
        <Typography variant='h2' className={classes.bookTitle} color='primary'>
          {book.title}
        </Typography>

        <Typography variant='h3' className={classes.bookSubtitle}>
          {book.subtitle}
        </Typography>
        <GatsbyImage
          image={book.frontCover.asset.gatsbyImageData}
          alt={`${book.title} front cover`}
          className={classes.authorImage}
          objectFit='contain'
        />
        <Authors />
        <Metadata />
        <Typography variant={descVariant} className={classes.shortDescription}>
          {book.shortDescription}
        </Typography>
        <LongDescription />
        <GatsbyImage
          image={book.backCover.asset.gatsbyImageData}
          alt={`${book.title} back cover`}
          className={classes.backCoverImage}
          objectFit='contain'
        />
      </Grid>
    </>
  )
}

export const query = graphql`
  query($bookId: String!) {
    book: sanityBook(id: { eq: $bookId }) {
      id
      isbn
      title
      subtitle
      shortDescription
      _rawLongDescription
      price
      publicationDate
      authors {
        id
        name
        slug {
          current
        }
      }
      resellers {
        label
        bookUrl
        formatsAvailable
      }
      backCover {
        asset {
          gatsbyImageData(
            fit: FILLMAX
            width: 800
            height: 800
            placeholder: BLURRED
          )
        }
      }
      frontCover {
        asset {
          gatsbyImageData(
            fit: FILLMAX
            width: 800
            height: 800
            placeholder: BLURRED
          )
        }
      }
    }
  }
`

export default BookPage
